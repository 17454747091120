import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { TextInput } from 'nhsuk-react-components';

const NumberBox = forwardRef((props, ref) => {
    const [error, setError] = useState(undefined);
    const [isValid, setIsValid] = useState(undefined);

    const ignoreWheelRef = useRef(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const validate = () => {
        const value = getValue();
        const questionName = props.question.name;
        if (props.question.mandatory && value.length === 0) {
            setError(`Enter the ${questionName}`);
            setIsValid(false);
            return;
        }

        if (value.length === 0) {
            setError(undefined);
            setIsValid(true);
            return;
        }

        validateMinMaxStep(value, questionName);
    };

    useImperativeHandle(ref, () => ({
        validate,
        isValid
    }));

    const validateMinMaxStep = (value, questionName) => {
        const { step, min, max } = props.question;
        const isMinNumber = typeof min === 'number';
        const isMaxNumber = typeof max === 'number';
        const isNumber = !isNaN(value);

        if (value.length > 0 && !isNumber) {
            setError(`${questionName} must be a number`);
            setIsValid(false);
            return;
        }

        if (value.length > 0 && isMaxNumber && isMinNumber && (value > max || value < min)) {
            setError(`${questionName} must be between ${min} and ${max}`);
            setIsValid(false);
            return;
        }

        if (value.length > 0 && isMinNumber && value < min) {
            setError(`${questionName} must be greater than or equal to ${min}`);
            setIsValid(false);
            return;
        }

        if (value.length > 0 && isMaxNumber && value > max) {
            setError(`${questionName} must be less than or equal to ${max}`);
            setIsValid(false);
            return;
        }

        if (value.length > 0 && step && value % step !== 0) {
            setError(`${questionName} must be in steps of ${step}`);
            setIsValid(false);
            return;
        }

        setError(undefined);
        setIsValid(true);
    };

    const getValue = () => {
        return props.question.value ?? '';
    };

    const handleChange = async event => {
        await props.onChange({
            questionId: props.question.id,
            value: event.target.value
        });

        validate();
    };

    const handleFocus = () => {
        ignoreWheelRef.current = event => {
            event.preventDefault();
        };
        document.activeElement.addEventListener('wheel', ignoreWheelRef.current);
    };

    const handleBlur = async event => {
        await handleChange(event);
        if (ignoreWheelRef.current) {
            document.activeElement.removeEventListener('wheel', ignoreWheelRef.current);
            ignoreWheelRef.current = null;
        }
    };

    var value = getValue();

    // Some questions may have 'hidden' in the json file, so we need to check if the value of that hidden attribute is
    // true then the question should be hidden..
    let hasHidden = false;
    hasHidden = props.question.hidden ?? true;
    /* if (value !== '') {
        hasHidden = false;


    } */
    useEffect(() => {
        if (props.question.clear) {
            props.question.value = undefined;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return !hasHidden || (hasHidden && !props.question.hidden) ? (
        <div style={{ display: props.question.showOnSummaryPage ? 'block' : 'none' }}>
            <TextInput
                id={props.id}
                label={props.question.name}
                hint={props.question.hint_text}
                error={error}
                step={props.question.step}
                max={props.question.max}
                min={props.question.min}
                type="number"
                width="10"
                value={props.question.clear ? undefined : value}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                required={props.question.mandatory}
                disabled={props.question.isReadOnly}
            />
        </div>
    ) : null;
});

export default NumberBox;
