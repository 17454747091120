import React, { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { InputWidth } from 'nhsuk-react-components/dist/esm/util/types/NHSUKTypes';
import { TextBoxProps } from '@form-controls';
import { TextInput } from 'nhsuk-react-components';

interface TextBoxState {
    error: string | undefined;
    isReadOnly: boolean;
    isValid: boolean;
}
//NEEDS TO BE REFACTORED
const TextBox: React.FC<TextBoxProps> = forwardRef((props, ref) => {
    const [state, setState] = useState<TextBoxState>({
        error: undefined,
        isReadOnly: false,
        isValid: false
    });

    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            isReadOnly: props.question.isReadOnly
        }));
    }, [props.question.isReadOnly]);

    /*     useEffect(() => {
        validate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.question.value]); // Validate on value change */

    useImperativeHandle(ref, () => ({
        validate,
        isValid: state.isValid
    }));

    const validate = () => {
        const value = getValue();
        const questionName = props.question.name;
        const max = props.question.max_length;
        const min = props.question.min_length;
        if (props.question.mandatory) {
            if (value.length === 0) {
                setState({
                    error: `Enter the ${props.question.name}`,
                    isValid: false,
                    isReadOnly: state.isReadOnly
                });
            } else if (min || max) {
                validateMinMax(min, max, value, questionName);
            } else {
                setState({
                    error: undefined,
                    isValid: true,
                    isReadOnly: state.isReadOnly
                });
            }
        } else if (min || max) {
            validateMinMax(min, max, value, questionName);
        } else {
            setState({
                error: undefined,
                isValid: true,
                isReadOnly: state.isReadOnly
            });
        }
    };

    const validateMinMax = (
        min: number | undefined,
        max: number | undefined,
        value: string,
        questionName: string
    ) => {
        let error = '';
        let isValid = true;

        if (min || max) {
            if (max && min && (value.length > max || value.length < min) && value.length > 0) {
                error = `${questionName} must be between ${min} and ${max} characters`;
                isValid = false;
            } else if (min && value.length < min && value.length > 0) {
                error = `${questionName} must be minimum ${min} characters in length`;
                isValid = false;
            } else if (max && value.length > max && value.length > 0) {
                error = `${questionName} exceeds the max length of ${max} characters`;
                isValid = false;
            }
            setState({
                error,
                isValid,
                isReadOnly: state.isReadOnly
            });
        }
    };

    const getValue = (): string => {
        return props.question.value ?? '';
    };

    const getWidth = (): InputWidth => {
        const validWidthValues: InputWidth[] = [2, 3, 4, 5, 10, 20, 30];
        if (props.question.width && validWidthValues.includes(props.question.width)) {
            return props.question.width;
        }
        return 10;
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        props.onChange({
            questionId: props.question.id,
            value: event.target.value
        });

        validate();
    };
    const value = getValue();
    return (
        <div
            style={{
                display: !props.question.hidden ? 'block' : 'none'
            }}
        >
            <TextInput
                id={props.id}
                label={props.question.name}
                hint={props.question.hint_text}
                error={state.error}
                width={getWidth()}
                value={value}
                onChange={handleChange}
                onBlur={handleChange}
                disabled={state.isReadOnly}
                required={props.question.mandatory}
            />
        </div>
    );
});

export default TextBox;
