import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { FormQuestions } from '@form';
import { Radios } from 'nhsuk-react-components';

const Radio = forwardRef((props, ref) => {
    const [error, setError] = useState(undefined);
    const [isValid, setIsValid] = useState(undefined);

    useImperativeHandle(ref, () => ({
        validate,
        isValid
    }));

    const validate = () => {
        if (props.question.mandatory) {
            if (!props.question.value) {
                setError('Please make a selection');
                setIsValid(false);
            } else {
                setError(undefined);
                setIsValid(true);
            }
        } else {
            setError(undefined);
            setIsValid(true);
        }
    };

    // This useEffect simulates the action of a checkbox being pressed, as we directly load data in on existing
    // submissions we dont trigger the onchance, this correctly triggers the onchange
    useEffect(() => {
        const fetchData = async () => {
            if (props.question.value != null) {
                // Simulate the change event for the preloaded value
                const syntheticEvent = {
                    target: {
                        value: props.question.value
                    }
                };
                await handleChange(syntheticEvent);
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = async event => {
        await props.onChange({
            questionId: props.question.id,
            linked_questions: props.question.linked_questions || null,
            option: props.question.options
                ? props.question.options.find(x => x.key === event.target.value)
                : null,
            value: event.target.value
        });
        props.question.value = event.target.value;
        validate();
        toggleConditionalRevealQuestions();
    };

    const toggleConditionalRevealQuestions = () => {
        const value = props.question.value;

        const option = props.question.options.find(x => x.key === value);
        if (option && option.questions) {
            props.onConditionalReveal({
                isRevealed: true,
                questionIds: option.questions.map(x => x.id)
            });
        }

        const questions = props.question.options
            .filter(x => x.key !== value)
            .filter(x => x.questions)
            .map(x => x.questions)
            .flat();

        if (questions.length > 0) {
            props.onConditionalReveal({
                isRevealed: false,
                questionIds: questions.map(x => x.id)
            });
        }
    };

    const { value, mandatory } = props.question;
    const hasHidden = props.question.hidden ?? true;

    return !hasHidden || (hasHidden && !props.question.hidden) ? (
        <Radios
            inline={props.question.display === 'inline'}
            label={props.question.name}
            hint={props.question.hint_text}
            value={value}
            error={error}
        >
            {props.question.options.map((option, index) => (
                <Radios.Radio
                    key={index}
                    value={option.key}
                    checked={option.key === value}
                    onChange={handleChange}
                    hint={option.hint_text}
                    required={mandatory}
                    conditional={
                        option.questions
                            ? option.questions.map((question, index) => (
                                  <FormQuestions
                                      question={props.questions.find(x => x.id === question.id)}
                                      key={index}
                                      onQuestionChange={props.onChange}
                                      ref={props.questions.find(x => x.id === question.id).ref}
                                  />
                              ))
                            : undefined
                    }
                >
                    {option.value}
                </Radios.Radio>
            ))}
        </Radios>
    ) : null;
});

export default Radio;
