import { Checkboxes, Fieldset } from 'nhsuk-react-components';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { FormQuestions } from '@form';

const Checkbox = forwardRef((props, ref) => {
    const [error, setError] = useState(undefined);
    const [isValid, setIsValid] = useState(undefined);

    useImperativeHandle(ref, () => ({
        validate,
        isValid
    }));

    const validate = () => {
        if (props.question.mandatory) {
            const value = getValue();

            if (!value.length) {
                setError('Please make a selection');
                setIsValid(false);
            } else {
                setError(undefined);
                setIsValid(true);
            }
        } else {
            setError(undefined);
            setIsValid(true);
            props.question.value = removeDuplicates(props.question.value);
        }
    };

    function removeDuplicates(list) {
        return [...new Set(list)];
    }

    const getValue = () => {
        const checkboxItems = props.question.value ?? [];
        const uniqueItems = [...new Set(checkboxItems)];
        return uniqueItems;
    };

    const handleChange = async event => {
        // This can go when we upgrade to React 17
        event.persist();

        const oldValue = getValue();
        const newValue = event.target.checked
            ? [...oldValue, event.target.value]
            : oldValue.filter(e => e !== event.target.value);

        await props.onChange({
            questionId: props.question.id,
            linked_questions: props.question.linked_questions || null,
            value: newValue
        });
        validate();
        props.question.value = newValue;

        const option = props.question.options.find(x => x.key === event.target.value);
        if (option && option.questions) {
            props.onConditionalReveal({
                isRevealed: event.target.checked,
                questionIds: option.questions.map(x => x.id)
            });
        }
    };

    useEffect(() => {
        if (props.question.value !== undefined) {
            validate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.question.value]);

    useEffect(() => {
        if (props.question.clear) {
            props.question.value = undefined;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*  This useEffect simulates the action of a checkbox being pressed, as we directly load data in on existing
    submissions we dont trigger the onchange, this correctly triggers the onchange */
    useEffect(() => {
        const simulateChangeEvent = async () => {
            const value = getValue();
            for (const option of props.question.options) {
                if (value.includes(option.key)) {
                    // Simulate the change event for each preloaded value
                    const syntheticEvent = {
                        target: {
                            checked: true,
                            value: option.key
                        },
                        persist: () => {} // Optional: mock the persist function
                    };
                    await handleChange(syntheticEvent);
                }
            }
        };

        simulateChangeEvent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var value = getValue();

    let hasHidden = false;
    hasHidden = props.question.hidden ?? true;
    return !hasHidden || (hasHidden && !props.question.hidden) ? (
        <Fieldset>
            <Fieldset.Legend>{props.question.name}</Fieldset.Legend>
            <Checkboxes hint={props.question.hint_text} name={props.question.name} error={error}>
                {props.question.options.map(option => (
                    <Checkboxes.Box
                        key={option.key}
                        value={option.key}
                        checked={value.includes(option.key)}
                        hint={option.hint_text}
                        onChange={handleChange}
                        required={props.question.mandatory}
                        ref={props.ref}
                        conditional={
                            option.questions
                                ? option.questions.map(question => (
                                      <FormQuestions
                                          question={props.questions.find(x => x.id === question.id)}
                                          questions={props.questions}
                                          key={question.id}
                                          onQuestionChange={props.onChange}
                                          ref={props.questions.find(x => x.id === question.id).ref}
                                      />
                                  ))
                                : undefined
                        }
                    >
                        {option.value}
                    </Checkboxes.Box>
                ))}
            </Checkboxes>
        </Fieldset>
    ) : null;
});

export default Checkbox;
